.select-state h2 {
  font-size: 1.75em;
}

@media screen and (max-width: 992px) {
  .select-state h2 {
    font-size: 1.75em;
  }
}

@media screen and (max-width: 768px) {
  .select-state h2 {
    font-size: 1.35em;
  }
}

@media screen and (max-width: 450px) {
  .select-state h2 {
    font-size: 1em;
    font-weight: bold;
  }
}
