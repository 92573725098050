.dsoa-banner h1 {
  font-size: 4.25em;
}

@media screen and (max-width: 992px) {
  .dsoa-banner h1 {
    font-size: 3.25em;
  }
}

@media screen and (max-width: 768px) {
  .dsoa-banner h1 {
    font-size: 2.25em;
  }
}

@media screen and (max-width: 450px) {
  .dsoa-banner h1 {
    font-size: 1.5em;
    font-weight: bold;
  }
}
